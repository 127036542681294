<section class="related-projects">
  <h1 class="related-projects__title h3 mb-2">{{ title }}</h1>
  <main class="related-projects__main">
    <ul class="row related-projects__list">
      <li
        class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-5 mb-2 related-projects__list__item"
        *ngFor="let project of projects; let i = index"
      >
        <app-project-card-small [project]="project"></app-project-card-small>
      </li>
      <li class="col-12 related-projects__list__item" *ngIf="projects.length < 1">
        {{ resultText }}
      </li>
    </ul>
  </main>
</section>
