import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Project } from '../../../projects/models';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectCardComponent implements OnInit {
  @Input() project: Project;
  isLoaded = false;
  constructor() {}

  ngOnInit(): void {}
}
