<section class="people-content-block" #el>
  <div class="block-content people-content-block__main" #main>
    <h1 class="h3 block-content__title people-content-block__title" *ngIf="block.title">{{ block.title }}</h1>
    <div
      class="block-content__text people-content-block__text"
      *ngIf="block.text"
      [innerHTML]="block.text | stripEmbed: (cookiesAccepted$ | async) | safe"
      fstrDynamicContent
    ></div>

    <footer class="people-content-block__main__footer" *ngIf="block.quote || block.buttonUrl">
      <div class="block-content__quote people-content-block__quote" *ngIf="block.quote">
        <blockquote>
          {{ block.quote }}
        </blockquote>
        <figcaption class="" *ngIf="block.author">{{ block.author }}</figcaption>
      </div>

      <ng-container *ngIf="block.buttonUrl && block.buttonText">
        <a
          *ngIf="block.routerLink"
          class="button color-black button--border button--small"
          [routerLink]="block.routerLink"
          [queryParams]="block.queryParams"
          [attr.data-text]="block.buttonText"
        >
          <span>{{ block.buttonText }}</span>
        </a>

        <a
          *ngIf="!block.routerLink"
          class="button color-black button--border button--small"
          [href]="block.buttonUrl"
          [attr.data-text]="block.buttonText"
        >
          <span>{{ block.buttonText }}</span>
        </a>
      </ng-container>
    </footer>
  </div>

  <div
    class="people-content-block__images"
    *ngIf="block.firstImageUrl || block.secondImageUrl"
    (fstrInViewport)="inview = $event"
    [once]="true"
  >
    <figure class="people-content-block__image image--1" *ngIf="block.firstImageUrl && inview">
      <img [alt]="block.firstImageCaption || block.title" [src]="block.firstImageUrl | resize: 1900" />
      <figcaption class="image-caption" *ngIf="block.firstImageCaption">{{ block.firstImageCaption }}</figcaption>
    </figure>
    <figure class="people-content-block__image image--2" *ngIf="block.secondImageUrl && inview">
      <img [alt]="block.secondImageCaption || block.title" [src]="block.secondImageUrl | resize: 1900" />
      <figcaption class="image-caption" *ngIf="block.secondImageCaption">{{ block.secondImageCaption }}</figcaption>
    </figure>
  </div>
</section>
