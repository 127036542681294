import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ContentBlock } from '../../models';

@Component({
  selector: 'app-slider-block',
  templateUrl: './slider-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderBlockComponent implements OnInit {
  @Input() contentBlock: ContentBlock;
  @Input() config;

  constructor() {}

  ngOnInit(): void {
    this.config = {
      gap: 0,
      showArrows: this.contentBlock.teasers?.length > 1,
      showBullets: false,
      perView: 1,
      startAt: 0,
      listenToEvents: true,
    };
  }
}
