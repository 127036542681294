import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Project } from '../../../projects/models';

@Component({
  selector: 'app-related-projects',
  templateUrl: './related-projects.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedProjectsComponent implements OnInit {
  @Input() projects: Project[];
  @Input() title = 'Related projects';
  @Input() resultText = 'No results';
  constructor() {}

  ngOnInit(): void {}
}
