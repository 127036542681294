import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeIntroBlockComponent } from './components/home-intro-block/home-intro-block.component';
import { PeopleContentBlockComponent } from './components/people-content-block/people-content-block.component';
import { ProjectContentBlockComponent } from './components/project-content-block/project-content-block.component';
import { PurposeContentBlockComponent } from './components/purpose-content-block/purpose-content-block.component';
import { CoreModule } from '../core/core.module';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromBlocks from './store';
import { PurposeTeaserComponent } from './components/purpose-teaser/purpose-teaser.component';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { SliderBlockComponent } from './components/slider-block/slider-block.component';
import { RelatedNewsComponent } from './components/related-news/related-news.component';
import { RelatedProjectsComponent } from './components/related-projects/related-projects.component';
import { ProjectCardSmallComponent } from './components/project-card-small/project-card-small.component';
import { EmployeeCardComponent } from './components/employee-card/employee-card.component';
import { CookieNgrxModule } from '@teamfoster/sdk/cookie-ngrx';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { SimpleImageComponent } from './components/simple-image/simple-image.component';
import { ProjectCardComponent } from './components/project-card/project-card.component';
import { VideoContentBlockComponent } from './components/video-content-block/video-content-block.component';
import { Html5VideoPlayerModule } from '@teamfoster/sdk/html5-video-player';
import { IconModule } from '@teamfoster/sdk/icon';
import { ImageModule } from '@teamfoster/sdk/image';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';

@NgModule({
  declarations: [
    HomeIntroBlockComponent,
    SliderBlockComponent,
    PeopleContentBlockComponent,
    ProjectContentBlockComponent,
    PurposeContentBlockComponent,
    PurposeTeaserComponent,
    RelatedNewsComponent,
    RelatedProjectsComponent,
    ProjectCardSmallComponent,
    EmployeeCardComponent,
    SimpleImageComponent,
    ProjectCardComponent,
    VideoContentBlockComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    InViewModule,
    RouterModule,
    DictionaryNgrxModule,
    Html5VideoPlayerModule,
    CookieNgrxModule,
    IconModule,
    TextUtilityModule,
    ImageModule,
    StoreModule.forFeature(fromBlocks.featureKey, fromBlocks.reducers, {
      metaReducers: fromBlocks.metaReducers,
    }),
    EffectsModule.forFeature(fromBlocks.effects),
  ],
  exports: [
    HomeIntroBlockComponent,
    SliderBlockComponent,
    PeopleContentBlockComponent,
    ProjectContentBlockComponent,
    PurposeContentBlockComponent,
    RelatedNewsComponent,
    RelatedProjectsComponent,
    ProjectCardSmallComponent,
    EmployeeCardComponent,
    SimpleImageComponent,
    ProjectCardComponent,
  ],
})
export class ContentBlockModule {}
