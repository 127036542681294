<!-- <pre> {{ contentBlock | json }}</pre> -->

<article class="simple-image row">
  <figure class="simple-image__figure col-md-8 col-lg-10 mb-lg-0">
    <img
      [src]="contentBlock.firstImageUrl | resize: 600:700:contentBlock.firstImageAnchor"
      loading="lazy"
      [alt]="contentBlock.firstImageCaption || contentBlock.title"
    />
  </figure>

  <main class="simple-image__content col-md-14 col-lg-12 offset-md-1 offset-lg-2">
    <div *ngIf="contentBlock.text" class="markdown-content">
      <h2 class="mt-2 h3" *ngIf="contentBlock.title">{{ contentBlock.title }}</h2>
      <div class="text-pre-line" [innerHTML]="contentBlock.text"></div>
    </div>

    <blockquote>«{{ contentBlock.quote }}»</blockquote>
  </main>
</article>
