<section
  (fstrInViewport)="inViewChange($event)"
  [once]="false"
  [threshold]="0"
  #header
  class="video-wrapper"
>
  <div class="video-wrapper__inner">

    <div class="aspect-ratio-element">
      <fstr-html5-video
        [class.set--player]="videoLoaded"
        [playerVars]="playerVars"
        (ready)="player($event)"
        *ngIf="videoBg"
        [src]="videoBg"
      ></fstr-html5-video>

      <div class="header__video__button" *ngIf="videoBg && asVideoPlayer && !videoPlayed">
        <button (click)="playVideo()">
          <fstr-icon anchor="icon-play"></fstr-icon>
          <strong>Play video</strong>
        </button>
      </div>
    </div>
  </div>
</section>