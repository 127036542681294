import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Inject, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { HeaderComponent } from '../../../core/components/header/header.component';

@Component({
  selector: 'app-video-content-block',
  templateUrl: './video-content-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoContentBlockComponent extends HeaderComponent implements OnInit {

  constructor(cd: ChangeDetectorRef, store: Store<any>,  @Inject(PLATFORM_ID) platformId,) {
    super(cd, store, platformId);
  }

  ngOnInit(): void {
    // console.log(this.playerVars)
  }

}
