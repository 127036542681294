<section
  class="project-content-block spacer-bottom-l"
  [class.type--past-block-one-image]="pastBlockOneImage"
  [class.type--one-image]="(block.firstImageUrl && !block.secondImageUrl) || (!block.firstImageUrl && block.secondImageUrl)"
  [class.type--two-images]="block.firstImageUrl && block.secondImageUrl"
>
  <div class="project-content-block__video" *ngIf="block.videoUrl">
    <!-- <app-header [playerVars]="playerVars" [asVideoPlayer]="true" [videoBg]="block.videoUrl"></app-header> -->
    <app-video-content-block [playerVars]="playerVars" [asVideoPlayer]="true" [videoBg]="block.videoUrl"></app-video-content-block>

    <span class="project-content-block__image__caption" *ngIf="block.videoCaption">{{ block.videoCaption }}</span>
  </div>
  <div class="project-content-block__image project-content-block__image--1" *ngIf="block.firstImageUrl">
    <figure
      class="project-content-block__image__crop"
      (click)="showImage.emit(block.firstImageUrl)"
      (fstrInViewport)="inview = $event"
      [once]="true"
    >
      <!--<img
        lang="lazy"
        *ngIf="inview"
        [alt]="block.firstImageCaption || block.title || ''"
        [src]="block.firstImageUrl | resize : 1600 : 1070 : block.firstImageAnchorPoint"
      />-->

      <app-image
        *ngIf="inview"
        [alt]="block.firstImageCaption || block.title || ''"
        [src]="block.firstImageUrl"
        [mode]="'crop'"
        [scale]="'both'"
        [anchor]="block.firstImageAnchorPoint"
      ></app-image>
    </figure>
    <div class="project-content-block__image__caption" *ngIf="block.firstImageCaption">{{ block.firstImageCaption }}</div>
  </div>
  <div class="project-content-block__image project-content-block__image--2" *ngIf="block.secondImageUrl">
    <figure class="project-content-block__image__crop" (click)="showImage.emit(block.secondImageUrl)" (fstrInViewport)="inview = $event">
      <!--<img
        *ngIf="inview"
        [alt]="block.secondImageCaption || block.title || ''"
        [src]="block.secondImageUrl | resize : 1600 : 1070 : block.firstImageAnchorPoint"
      />-->

      <app-image
        *ngIf="inview"
        [alt]="block.secondImageCaption || block.title || ''"
        [src]="block.secondImageUrl"
        [mode]="'crop'"
        [scale]="'both'"
        [anchor]="block.secondImageAnchorPoint"
      ></app-image>
    </figure>
    <div class="project-content-block__image__caption" *ngIf="block.secondImageCaption">{{ block.secondImageCaption }}</div>
  </div>

  <div
    class="block-content project-content-block__main"
    *ngIf="block.title || block.text || block.quote || (block.buttonUrl && block.buttonText)"
    #main
  >
    <h1 class="h3 block-content__title project-content-block__title" *ngIf="block.title">{{ block.title }}</h1>
    <div
      class="project-content-block__text markdown-content"
      *ngIf="block.text"
      [innerHTML]="block.text | stripEmbed : (cookiesAccepted$ | async) | safe"
      fstrDynamicContent
    ></div>

    <footer class="project-content-block__main__footer" *ngIf="block.quote || (block.buttonUrl && block.buttonText)">
      <div class="block-content__quote project-content-block__quote" *ngIf="block.quote || block.author">
        <blockquote *ngIf="block.quote">
          {{ block.quote }}
        </blockquote>
        <figcaption class="" *ngIf="block.author">{{ block.author }}</figcaption>
      </div>

      <ng-container *ngIf="block.buttonUrl && block.buttonText">
        <a
          *ngIf="block.routerLink"
          class="button color-black button--border button--small"
          [routerLink]="block.routerLink"
          [queryParams]="block.queryParams"
          [attr.data-text]="block.buttonText"
        >
          <span>{{ block.buttonText }}</span>
        </a>

        <a
          *ngIf="!block.routerLink"
          class="button color-black button--border button--small"
          [href]="block.buttonUrl"
          [attr.data-text]="block.buttonText"
        >
          <span>{{ block.buttonText }}</span>
        </a>
      </ng-container>
    </footer>
  </div>
</section>
