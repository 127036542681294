import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ContentBlock } from '../../models';

@Component({
  selector: 'app-simple-image',
  templateUrl: './simple-image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleImageComponent implements OnInit {
  @Input() contentBlock: ContentBlock;

  constructor() { }

  ngOnInit(): void {
  }
}
