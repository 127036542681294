import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Project } from '../../../projects/models';

@Component({
  selector: 'app-project-card-small',
  templateUrl: './project-card-small.component.html',

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectCardSmallComponent implements OnInit {
  @Input() project: Project;
  constructor() {}

  ngOnInit(): void {}
}
