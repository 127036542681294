import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Employee } from '../../../people/models/employee';

@Component({
  selector: 'app-employee-card',
  templateUrl: './employee-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeCardComponent implements OnInit {
  @Input() data: Employee;
  @Input() asContact: boolean;
  inView = false;
  constructor() {}

  ngOnInit(): void {}
}
