import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { News } from '../../../content/models';

@Component({
  selector: 'app-related-news',
  templateUrl: './related-news.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedNewsComponent implements OnInit {
  @Input() title = 'Related news';
  @Input() news: News[];
  @Input() filterBy: {};
  @Input() resultText = 'No results';
  constructor() {}

  ngOnInit(): void {}
}
