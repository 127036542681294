<div class="slider-block">
  <app-slider [cursorArrow]="false" [config]="config" [total]="contentBlock.teasers.length">
    <app-header
      [class.set--inverted]="item.invertColors"
      [inverted]="item.invertColors"
      [imageUrl]="item.imageUrl"
      [imageContain]="item.maintainRatio"
      [imageAnchor]="item.imageAnchor"
      [title]="item.title"
      [intro]="item?.imageCaption"
      [button]="{ title: item.buttonText, url: item.buttonUrl, routerLink: item.routerLink }"
      *ngFor="let item of contentBlock.teasers"
    ></app-header>
  </app-slider>
  <footer *ngIf="contentBlock.routerLink || contentBlock.buttonUrl" class="container spacer-top-m text-center home-slider__footer">
    <a
      class="button color-black button--border"
      *ngIf="contentBlock.routerLink"
      [routerLink]="contentBlock.routerLink"
      [queryParams]="contentBlock.queryParams"
      [attr.data-text]="contentBlock.buttonText"
    >
      <span>{{ contentBlock.buttonText }}</span>
    </a>
    <a
      class="button color-black button--border"
      *ngIf="contentBlock.buttonUrl && !contentBlock.routerLink"
      [href]="contentBlock.buttonUrl"
      [attr.data-text]="contentBlock.buttonText"
    >
      <span>{{ contentBlock.buttonText }}</span>
    </a>
  </footer>
</div>
