import { Component, OnInit, ChangeDetectionStrategy, Input, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-home-intro-block',
  templateUrl: './home-intro-block.component.html',

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeIntroBlockComponent implements OnInit {
  @Input() summary = '';
  @Input() text = '';

  @ViewChild('homeText') el: ElementRef;

  openDetail = false;
  constructor() {}

  ngOnInit(): void {}

  toggle() {
    this.openDetail = !this.openDetail;

    if (!this.openDetail) {
      // scrollback to top with small timeout
      setTimeout(() => {
        this.el.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 600);
    }
  }
}
