import * as fromPages from './content-blocks.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'content-blocks';

export interface ContentBlocksState {
  contentBlocks: fromPages.ContentBlocksState;
}

export const reducers: ActionReducerMap<ContentBlocksState> = {
  contentBlocks: fromPages.ContentBlockReducer,
};

export const getContentBlocksState = createFeatureSelector<ContentBlocksState>(featureKey);

export const metaReducers: MetaReducer<ContentBlocksState>[] = !environment.production ? [] : [];
