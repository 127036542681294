import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { CookiePreferences, getCookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { Observable } from 'rxjs';
import { ContentState } from '../../../content/store';
import { ContentBlock } from '../../models/content-block.model';

@Component({
  selector: 'app-people-content-block',
  templateUrl: './people-content-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeopleContentBlockComponent implements OnInit {
  @Input() block: ContentBlock;
  cookiesAccepted$: Observable<CookiePreferences>;
  inview = false;
  constructor(private store: Store<ContentState>) {}

  ngOnInit(): void {
    this.cookiesAccepted$ = this.store.select(getCookiePreferences);
  }
}
