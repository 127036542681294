<section class="related-news">
  <h1 class="related-news__title h3 mb-2">{{ title }}</h1>
  <main class="related-news__main">
    <ul class="related-news__list">
      <li class="related-news__list__item" *ngFor="let newsItem of news; let i = index" [class.news__item--featured]="i === 0">
        <app-news-card [newsItem]="newsItem"></app-news-card>
      </li>
      <li class="related-news__list__item" *ngIf="news.length < 1">
        {{ resultText }}
      </li>
    </ul>
  </main>
  <footer class="related-news__footer" *ngIf="filterBy">
    <a class="button color-black button--border" [routerLink]="['', 'news']" [queryParams]="filterBy" data-text="More news">
      <span>More news</span>
    </a>
  </footer>
</section>
