import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ContentBlockTeaser } from '../../models';

@Component({
  selector: 'app-purpose-teaser',
  templateUrl: './purpose-teaser.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurposeTeaserComponent implements OnInit {
  @Input() data: ContentBlockTeaser;

  inview = false;
  constructor() {}

  ngOnInit(): void {}
}
