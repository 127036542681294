import { createReducer, on } from '@ngrx/store';
import { ContentBlock } from '../../models';
import * as pageActions from '../actions/content-blocks.action';
import * as utility from '../../../utility';

export interface ContentBlocksState {
  entities: { [id: number]: ContentBlock };
  loaded: number[];
  loading: number[];
  error?: any;
}

export const initialState: ContentBlocksState = {
  entities: {},
  loaded: [],
  loading: [],
};

export const ContentBlockReducer = createReducer(
  initialState,
  on(pageActions.LoadContentBlocks, (state: ContentBlocksState, { pageId }) => {
    return {
      ...state,
      loading: [...state.loading, pageId],
    };
  }),
  on(pageActions.LoadContentBlocksSuccess, (state: ContentBlocksState, { pageId, contentBlocks }) => {
    const entities = utility.ToEntities(contentBlocks, 'id', state.entities);
    return {
      ...state,
      entities,
      loading: [...state.loading.filter(a => a !== pageId)],
      loaded: [...state.loaded, pageId],
    };
  }),
  on(pageActions.LoadContentBlocksFail, (state: ContentBlocksState, { pageId, error }) => {
    return {
      ...state,
      loading: [...state.loading.filter(a => a !== pageId)],
      loaded: [...state.loaded.filter(a => a !== pageId)],
      error,
    };
  })
);

export const getContentBlocksEntities = (state: ContentBlocksState) => state.entities;
export const getContentBlocksLoading = (state: ContentBlocksState) => state.loading;
export const getContentBlocksLoaded = (state: ContentBlocksState) => state.loaded;
