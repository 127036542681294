import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Inject,
  PLATFORM_ID,
  AfterViewInit,
  HostListener,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { CookiePreferences, getCookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { Observable } from 'rxjs';
import { ContentState } from '../../../content/store';
import { ContentBlock } from '../../models/content-block.model';

@Component({
  selector: 'app-purpose-content-block',
  templateUrl: './purpose-content-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurposeContentBlockComponent implements OnInit, AfterViewInit {
  @Input() block: ContentBlock;
  @ViewChild('el') el: ElementRef;
  cookiesAccepted$: Observable<CookiePreferences>;

  get teasersLength() {
    if (this.block.teasers) {
      return this.block.teasers.length;
    }
    return;
  }
  constructor(@Inject(PLATFORM_ID) private platformId, private store: Store<ContentState>) {}

  ngOnInit(): void {
    this.cookiesAccepted$ = this.store.select(getCookiePreferences);
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.getMainSize();
      }, 300);
    }
  }

  getMainSize() {
    const listItems = this.el.nativeElement.querySelectorAll('.purpose-content-block__teasers__item');
    listItems.forEach((element, index) => {
      // by every third
      if ((index + 1) % 3 === 0) {
        const prevMain = listItems[0];
        const main = prevMain.querySelector('.purpose-teaser'); // get first main element
        const main2 = listItems[1].querySelector('.purpose-teaser'); // get second main element
        const purposeMinus = main2.clientHeight - main.clientHeight;
        // console.log(purposeMinus, main.clientHeight, main2.clientHeight);
        element.style.setProperty('--purposeMain', `${purposeMinus}px`);
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getMainSize();
  }
}
