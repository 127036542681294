<article class="purpose-teaser" [ngClass]="data.invertColors ? 'color-black' : 'color-white'">
  <figure class="purpose-teaser__crop" (fstrInViewport)="inview = $event" [once]="true">
    <img *ngIf="inview" [alt]="data.imageCaption || data.title" [src]="data.imageUrl | resize: 1200:null:data.imageAnchor" />
    <!-- <div class="purpose-teaser__image__caption" *ngIf="data.imageCaption">{{ data.imageCaption }}</div> -->
  </figure>

  <h1 class="mb-0 purpose-teaser__title spacer-bottom-m">
    <a
      *ngIf="data.routerLink"
      [ngClass]="data.invertColors ? 'color-black' : 'color-white'"
      class="button button--border button--small type-body-small purpose-teaser__link"
      [routerLink]="data.routerLink"
      [queryParams]="data.queryParams"
      [attr.data-text]="'teaser-hover-text' | fromDictionary"
    >
      <span>{{ data.title }}</span>
    </a>
    <a
      *ngIf="!data.routerLink"
      [ngClass]="data.invertColors ? 'color-black' : 'color-white'"
      class="button button--border button--small purpose-teaser__link h3"
      [href]="data.buttonUrl"
      [attr.data-text]="'teaser-hover-text' | fromDictionary"
    >
      <span>{{ data.title }}</span>
    </a>
  </h1>
</article>
