<article class="employee-card" [class.no--image]="!data.image">
  <figure class="employee-card__crop" >
    <!-- <img loading="lazy" [alt]="data.name" [src]="data.image | resize: 750:500" /> -->

    <app-image
      [src]="data.image"
      *ngIf="data.image"
      loading="lazy"
      [alt]="data.name"
      [width]="750"
      [height]="500"
      [imageSizes]="[300, 500, 750]"
    ></app-image>
  </figure>
  <div class="employee-card__content" *ngIf="!asContact">
    <h1 class="employee-card__title">
      {{ data.name }}
      <em *ngIf="data.office">{{ data.office.name }}</em>
    </h1>
    <div class="employee-card__function" *ngIf="data.function">{{ data.function }}</div>
    <div class="employee-card__text" *ngIf="data.text">{{ data.text }}</div>
  </div>
  <div class="employee-card__content" *ngIf="asContact">
    <div class="employee-card__location" *ngIf="data.location">{{ data.location }}</div>
    <div class="employee-card__function" *ngIf="data.function">{{ data.function }}</div>
    <h1 class="employee-card__title">{{ data.name }}</h1>
    <div class="employee-card__email" *ngIf="data.email">
      <a [href]="'mailto:' + data.email">{{ data.email }}</a>
    </div>
  </div>
</article>
