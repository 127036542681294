import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromContentBlocks from '../reducers/content-blocks.reducer';
import { ContentBlock } from '../../models';
import * as fromRoot from '../../../store';

export const getContentBlockState = createSelector(
  fromFeature.getContentBlocksState,
  (state: fromFeature.ContentBlocksState) => state.contentBlocks
);

export const getContentBlockEntities = createSelector(getContentBlockState, fromContentBlocks.getContentBlocksEntities);

export const getAllContentBlocks = createSelector(getContentBlockEntities, entities => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getOrderedContentBlocks = createSelector(getAllContentBlocks, (Pages: ContentBlock[]) => {
  const orderedPages = Pages.sort((a, b) => a.order - b.order);
  return orderedPages;
});

export const getContentBlocksLoaded = createSelector(getContentBlockState, fromContentBlocks.getContentBlocksLoaded);
export const getContentBlocksLoading = createSelector(getContentBlockState, fromContentBlocks.getContentBlocksLoading);

export const getSelectedContentBlocksLoaded = createSelector(getContentBlocksLoaded, fromRoot.getRouterState, (loaded, router) => {
  const pageId = router.state.params?.pageId || router.state.data?.pageId;
  return loaded.findIndex(a => a === pageId) >= 0;
});

export const getSelectedContentBlocksLoading = createSelector(getContentBlocksLoading, fromRoot.getRouterState, (loading, router) => {
  const pageId = router.state.params?.pageId || router.state.data?.pageId;
  return loading.findIndex(a => a === pageId) >= 0;
});

export const getSelectedContentBlocks = createSelector(
  getOrderedContentBlocks,
  fromRoot.getRouterState,
  (blocks, router): ContentBlock[] => {
    const pageId = router.state.params?.pageId || router.state.data?.pageId;
    return blocks.filter(a => a.pageId === pageId);
  }
);
